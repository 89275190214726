/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import styled from "styled-components";

import React from "react";

import {APP_COLORS} from "../../common/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faStar as faStarSolid, faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faStar as faStarOutline} from "@fortawesome/free-regular-svg-icons";
import {faBadgeCheck} from "@fortawesome/pro-solid-svg-icons";

const BoxContainer = styled.div `
  //display: grid;
  //grid-template-rows: 25px minmax(25px, max-content) 1fr;
  padding: 5px 10px 0 0;
`;

const CardLabel = styled.div `
  font-size: 13px;
  font-weight: bold;
  color: ${APP_COLORS['gray-card']};
`;

const LeadText = styled.div `
  font-size: 15px;
  font-weight: bold;
  color: ${APP_COLORS.blue};
`;

const SubText = styled.div `
  padding-top: 1px;
  font-size: 14px;
  font-style: italic;
  color: ${APP_COLORS['gray-card']};
`;

const ContactContainer = styled.div `
  padding-top: 1px;
`;

const OnlineNormalizedAvgScoreContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1px;
`;

const StarContainer = styled.div `
  padding-left: 1px;
  padding-right: 1px;
`;

const BTVerifiedContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1px;
`;


export default function ContractorDirectorySearchResultContractorBox(props) {
    const {
        item,
    } = props;

    const companyAddress = `${item.companyAddressDeliveryLine1} ${item.companyAddressCity}, ${item.companyAddressRegion} ${item.companyAddressPostalCode}`;

    const companyEmail = (
        item.companyEmail && (
                <>
                    <FontAwesomeIcon
                        color={APP_COLORS['orange']}
                        icon={faEnvelope}
                    />&nbsp;&nbsp;
                    <a target='_blank' rel="noreferrer" href={`mailto:${item.companyEmail}`}>
                        <>{item.companyEmail && item.companyEmail.length > 30 ?
                            `${item.companyEmail.substring(0, 30)}...` : item.companyEmail
                        }</>
                    </a>&nbsp;&nbsp;
                </>
            )
    );

    const companyPhone = (
        item.companyPhone && (
            <>
                <FontAwesomeIcon
                    color={APP_COLORS['orange']}
                    icon={faPhone}
                />&nbsp;&nbsp;
                <a target='_blank' rel="noreferrer" href={`tel:${item.companyPhone}`}>
                    {item.companyPhone}
                </a>
            </>
        )
    );

    let companyChannels;
    if (companyPhone && companyEmail) companyChannels = (<>{companyPhone}&nbsp;&nbsp;/&nbsp;&nbsp;{companyEmail}</>);
    if (companyPhone && !companyEmail) companyChannels = (<>{companyPhone}</>);
    if (!companyPhone && companyEmail) companyChannels = (<>{companyEmail}</>);

    const contactName = `${item.contactNameGivenName} ${item.contactNameSurname}`;

    const contactEmail = (
        item.contactEmail && (
            <>
                <FontAwesomeIcon
                    color={APP_COLORS['orange']}
                    icon={faEnvelope}
                />&nbsp;&nbsp;
                <a target='_blank' rel="noreferrer" href={`mailto:${item.contactEmail}`}>
                    <>{item.contactEmail && item.contactEmail.length > 30 ?
                        `${item.contactEmail.substring(0, 30)}...` : item.contactEmail
                    }</>
                </a>&nbsp;&nbsp;
            </>
        )
    );

    const contactPhone = (
        item.contactPhone && (
            <>
                <FontAwesomeIcon
                    color={APP_COLORS['orange']}
                    icon={faPhone}
                />&nbsp;&nbsp;
                <a target='_blank' rel="noreferrer" href={`tel:${item.contactPhone}`}>
                    {item.contactPhone}
                </a>
            </>
        )
    );

    let contactChannels;
    if (contactPhone && contactEmail) contactChannels = (<>{contactPhone}&nbsp;&nbsp;/&nbsp;&nbsp;{contactEmail}</>);
    if (contactPhone && !contactEmail) contactChannels = (<>{contactPhone}</>);
    if (!contactPhone && contactEmail) contactChannels = (<>{contactEmail}</>);

    let onlineNormalizedAvgScore;
    if (item.companyProfileOnlineScoreNormalizedAvg) {
        const score =  Math.round(Number.parseFloat(item.companyProfileOnlineScoreNormalizedAvg));
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (score >= i) {
                stars.push((
                    <FontAwesomeIcon
                        color={APP_COLORS['yellow']}
                        icon={faStarSolid}
                    />
                ))
            } else {
                stars.push((
                    <FontAwesomeIcon
                        color={APP_COLORS['yellow']}
                        icon={faStarOutline}
                    />
                ))
            }
        }

        onlineNormalizedAvgScore = (
            <OnlineNormalizedAvgScoreContainer>
                {stars.map((s, i) => (
                    <StarContainer key={i}>
                        {s}
                    </StarContainer>
                ))}&nbsp;<span style={{fontSize: '12px',  fontWeight:200, fontStyle: 'italic'}}>({item.companyProfileOnlineScoreNormalizedAvg} / 5)</span>
            </OnlineNormalizedAvgScoreContainer>
        );
    }

    let btVerifiedIcon;
    if (item.btVerified) {
        btVerifiedIcon = (
            <BTVerifiedContainer>
                <FontAwesomeIcon
                    color="#00d8d8"
                    icon={faBadgeCheck}
                    style={{fontSize: '18px'}}
                />&nbsp;<span style={{fontSize: '12px',  fontWeight:200, fontStyle: 'italic'}}>(Buildertrend Verified)</span>
            </BTVerifiedContainer>
        );
    }

    return (
        <BoxContainer>
            <CardLabel>Contractor <span style={{fontSize: '12px',  fontWeight:200, fontStyle: 'italic'}}>{item.companyScore}</span></CardLabel>
            <LeadText>
                <>{item.companyName && item.companyName.length > 40 ?
                    `${item.companyName.substring(0, 40)}...` : item.companyName
                }</>
                &nbsp;&nbsp;
                {item.companyURLStatus === 'Active' && item.companyURL && (
                    <a target='_blank' href={`https://${item.companyURL && item.companyURL.replace(/(^\w+:|^)\/\//, '')}`}>
                        <FontAwesomeIcon
                            color={APP_COLORS['orange']}
                            icon={faArrowUpRightFromSquare}
                            style={{fontSize: '18px'}}
                        />
                    </a>
                )}
            </LeadText>
            <SubText>
                <>{companyAddress && companyAddress.length > 75 ?
                    `${companyAddress.substring(0, 75)}...` : companyAddress
                }</>
                {
                    (item.companyPhone || item.contactPhone) && (
                        <>&nbsp;&nbsp;
                            <FontAwesomeIcon
                                color={APP_COLORS['orange']}
                                icon={faPhone}
                            />
                        </>
                    )
                }
                {
                    (item.companyEmail || item.companyEmail) && (
                        <>&nbsp;&nbsp;
                            <FontAwesomeIcon
                                color={APP_COLORS['orange']}
                                icon={faEnvelope}
                            />
                        </>
                    )
                }
            </SubText>
            {/*{*/}
            {/*    companyChannels && (*/}
            {/*        <SubText>*/}
            {/*            {companyChannels}*/}
            {/*        </SubText>*/}
            {/*    )*/}
            {/*}*/}
            {item.contactId && (
                <ContactContainer>
                    <LeadText>
                        <>{contactName && contactName.length > 40 ?
                            `${contactName.substring(0, 40)}...` : contactName
                        }</>
                        {item.contactTitle && (
                            <span style={{
                                fontSize: '14px',
                                fontStyle: 'italic',
                                color: APP_COLORS['gray-card'],
                                fontWeight: 400
                            }}>&nbsp;{item.contactTitle && item.contactTitle.length > 75 ?
                                `(${item.contactTitle.substring(0, 75)}...)` : `(${item.contactTitle})`
                            }</span>
                        )}
                    </LeadText>
                    {/*{*/}
                    {/*    contactChannels && (*/}
                    {/*        <SubText>*/}
                    {/*            {contactChannels}*/}
                    {/*        </SubText>*/}
                    {/*    )*/}
                    {/*}*/}
                </ContactContainer>
            )}
            {
                item.btVerified && (
                    btVerifiedIcon
                )
            }
            {
                onlineNormalizedAvgScore && (
                    onlineNormalizedAvgScore
                )
            }
        </BoxContainer>
    )
}

