/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {Component} from 'react';
import {
    Row,
    Col,
    Image,
} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faGlobe,
    faEnvelope,
    faPhone,
    faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';

import companyIcon from '../../../../common/assets/img/ToolbeltIcons_Artboard_19.png'
import {APP_COLORS} from '../../../../common/Constants';
import * as CubeApi from '../../../../common/CubeApi';
import config from '../../../../config';
import styled from 'styled-components';
import ExploreMapFlyoutDetailUnCluster from "./ExploreMapFlyoutDetailUnCluster";

const LeadTextSmall = styled.span`
    font-size: 11px;
    padding-left: 5px;
    font-style: italic;
    color: ${APP_COLORS['gray-600']};
`;

const TextLink = styled.a`
    font-size: 12px;
    padding-left: 5px;
    font-style: italic;
    color: ${APP_COLORS['tech-blue']};
`;

const TextLinkContainer = styled.div`
    margin-top: 10px;

    display: grid;
    grid-template-columns: 25px 25px 25px 25px;
`;

const ScoreText = styled.span`
    font-size: 12px;
    font-weight: 400;
    font-style: italic;

    color: ${APP_COLORS['gray-card']};

    margin-left: 5px;
`;

export default class ExploreMapFlyoutDetailSelectedProductRecord extends Component {
    static propTypes = {};

    static defaultProps = {
        selectedUnclusteredPoint: undefined,
        selectedUnclusteredDataPoint: undefined
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedUnclusteredDataPointRecord: null
        };
    }

    componentDidMount() {
        console.log('ExploreMapFlyoutDetailSelectedProduct - Product');
        this.setState({
            loading: true
        }, () => {

            const cube = this.props.exploreMode === 'COMPANY' ? 'Company' : 'Contact';
            _getDataPointRecord(this.props.currentFilters, cube, this.props.selectedPoint)
                .then((dataPointRecord) => {
                    this.setState({
                        loading: false,
                        selectedUnclusteredDataPointRecord: dataPointRecord
                    })
                })
        });
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.selectedPoint &&
            prevProps.selectedPoint &&
            prevProps.selectedPoint.properties &&
            this.props.selectedPoint.properties &&
            this.props.selectedPoint.properties.id !== prevProps.selectedPoint.properties.id
        ) {
            this.setState({
                loading: true
            }, () => {

                const cube = this.props.exploreMode === 'COMPANY' ? 'Company' : 'Contact';
                _getDataPointRecord(this.props.currentFilters, cube, this.props.selectedPoint)
                    .then((dataPointRecord) => {
                        this.setState({
                            loading: false,
                            selectedUnclusteredDataPointRecord: dataPointRecord
                        })
                    })
            });
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <Row>
                    <Col>
                        <div className="flyout-detail">
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Spinner
                                    style={{marginTop: '30px'}}
                                    as="span"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                    variant="info"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            )
        }

        const {
            company_name,
            company_score,
            company_url,
            company_phone,
            company_email,
            company_address_delivery_line_1,
            company_address_city,
            company_address_region,
            company_address_postal_code,
        } = this.state.selectedUnclusteredDataPointRecord;

        const companyAddressLastLine = `${company_address_city}, ${company_address_region} ${company_address_postal_code}`;
        const densityIcons = [
            {attr: 'company_url', icon: faGlobe},
            {attr: 'company_email', icon: faEnvelope},
            {attr: 'company_phone', icon: faPhone},
            {attr: 'company_address_delivery_line_1', icon: faMapMarkerAlt},
        ];
        const densityIconsJsx = [];
        densityIcons.forEach((i, idx) => {
            if (this.state.selectedUnclusteredDataPointRecord[i.attr]) {
                let marginLeft = '10px';
                if (densityIconsJsx.length === 0) marginLeft = '0px';
                if (i.attr === 'company_url' && company_url) {
                    densityIconsJsx.push(
                        <div key={idx} style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginTop: -5
                        }}>
                            <TextLink
                                href={`https://${company_url && company_url.replace(/(^\w+:|^)\/\//, '')}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    color={APP_COLORS['orange']}
                                    icon={i.icon}
                                    style={{fontSize: '16px'}}
                                />
                            </TextLink>
                        </div>
                    );
                }

                if (i.attr === 'company_phone' && company_phone) {
                    densityIconsJsx.push(
                        <div key={idx} style={{marginTop: -5}}>
                            <TextLink
                                href={`tel:${company_phone}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    color={APP_COLORS['blue']}
                                    icon={i.icon}
                                    style={{fontSize: '16px'}}
                                />
                            </TextLink>
                        </div>
                    )
                }

                if (i.attr === 'company_email' && company_email) {
                    densityIconsJsx.push(
                        <div key={idx} style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginTop: -5
                        }}>
                            <TextLink
                                href={`mailto:${company_email}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    color={APP_COLORS['blue']}
                                    icon={i.icon}
                                    style={{fontSize: '16px'}}
                                />
                            </TextLink>
                        </div>
                    )
                }
            }
        });

        return (
            <Row>
                <Col>
                    <div className="explore-map-flyout-detail explore-map-flyout-detail-data-point">
                        {/* Flyout detail*/}
                        <div className={"flyout-detail-section"}>
                            <div className="icon-container">
                                <Image
                                    className="icon"
                                    src={companyIcon}
                                    alt="Product Icon"
                                    fluid
                                />
                                <div>
                                    <div className="lead-text-container">
                                        <div className="lead-text-items">
                                            <span className="lead-text-label">
                                                <a>
                                                    {company_name}
                                                </a>
                                                <ScoreText>{company_score}</ScoreText>
                                            </span>
                                            <LeadTextSmall>{company_address_delivery_line_1}</LeadTextSmall>
                                            <LeadTextSmall>{companyAddressLastLine}</LeadTextSmall>
                                        </div>
                                    </div>
                                    <div>
                                        <TextLinkContainer>
                                            {densityIconsJsx.length > 0 && (
                                                <>
                                                    {densityIconsJsx}
                                                </>
                                            )}
                                        </TextLinkContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ExploreMapFlyoutDetailUnCluster item={this.state.selectedUnclusteredDataPointRecord}/>
                    </div>
                </Col>
            </Row>
        )
    }
}

async function _getDataPointRecord(filters, cube, point) {
    let companyId;
    if (point && point.properties && point.properties.companyId) {
        companyId = point.properties.companyId;
    } else {
        companyId = `${point.properties.id}`
    }

    const _filters = [];
    _filters.push({
        dimension: `CompanyContractorUniverse.company_identity`,
        operator: 'equals',
        values: [`${companyId}`]
    });
    const payload = {
        dimensions: [
            'CompanyContractorUniverse.company_id',
            'CompanyContractorUniverse.company_identity',
            'CompanyContractorUniverse.company_score',
            'CompanyContractorUniverse.company_name',
            'CompanyContractorUniverse.company_url',
            'CompanyContractorUniverse.company_address_delivery_line_1',
            'CompanyContractorUniverse.company_address_city',
            'CompanyContractorUniverse.company_address_region',
            'CompanyContractorUniverse.company_address_postal_code',
            'CompanyContractorUniverse.company_phone',
            'CompanyContractorUniverse.company_email',
            'CompanyContractorUniverse.company_class_trades',
            'CompanyContractorUniverse.company_class_primary_trade',
            'CompanyContractorUniverse.company_class_primary_trade_score',
            'CompanyContractorUniverse.company_address_postal_code',
            'CompanyContractorUniverse.company_profile_manufacturer_profiles',
            'CompanyContractorUniverse.company_profile_online_profiles',
            'CompanyContractorUniverse.company_profile_reviews_ratings_avg',
            'CompanyContractorUniverse.company_profile_association_profiles',
            'CompanyContractorUniverse.company_source_cnt',
            'CompanyContractorUniverse.company_authoritative_source_cnt',
            'CompanyContractorUniverse.company_source_labels',
            'CompanyContractorUniverse.company_source_first_touch',
            'CompanyContractorUniverse.company_source_last_touch',
            'CompanyContractorUniverse.company_source_cnt',
            'CompanyContractorUniverse.company_profile_cnt',
            'CompanyContractorUniverse.company_license_cnt',
            'CompanyContractorUniverse.company_permit_cnt',
            'CompanyContractorUniverse.company_policy_cnt',
            'CompanyContractorUniverse.company_input_cnt',
            'CompanyContractorUniverse.contact_id',
            'CompanyContractorUniverse.contact_name_given_name',
            'CompanyContractorUniverse.contact_name_surname',
            'CompanyContractorUniverse.contact_title',
            'CompanyContractorUniverse.contact_phone',
            'CompanyContractorUniverse.contact_email',
            'CompanyContractorUniverse.contact_email_status',
            'CompanyContractorUniverse.contact_roles',
            'CompanyContractorUniverse.company_ind_residential',
            'CompanyContractorUniverse.company_ind_commercial',
            'CompanyContractorUniverse.company_ind_industrial',
            'CompanyContractorUniverse.company_ind_exterior',
            'CompanyContractorUniverse.company_ind_interior',
            'CompanyContractorUniverse.company_ind_licensed',
            'CompanyContractorUniverse.company_ind_bonded',
            'CompanyContractorUniverse.company_ind_insured',
            'CompanyContractorUniverse.company_profile_cnt',
            'CompanyContractorUniverse.company_license_cnt',
            'CompanyContractorUniverse.company_policy_cnt',
            'CompanyContractorUniverse.company_permit_cnt',
            'CompanyContractorUniverse.company_contact_cnt',
            'CompanyContractorUniverse.company_source_cnt',
        ],
        timeDimensions: [],
        ungrouped: true,
        filters: _filters
    };

    const resultSet = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
        payload
    );

    // Flatten result set
    if (resultSet.length !== 1) {
        console.error('returned unexpected result set (gt 1)');
        throw new Error('company id not found');
    }

    return {
        company_identity: resultSet[0]['CompanyContractorUniverse.company_identity'],
        company_score: resultSet[0]['CompanyContractorUniverse.company_score'],
        company_name: resultSet[0]['CompanyContractorUniverse.company_name'],
        company_url: resultSet[0]['CompanyContractorUniverse.company_url'],
        company_address_delivery_line_1: resultSet[0]['CompanyContractorUniverse.company_address_delivery_line_1'],
        company_address_city: resultSet[0]['CompanyContractorUniverse.company_address_city'],
        company_address_region: resultSet[0]['CompanyContractorUniverse.company_address_region'],
        company_address_postal_code: resultSet[0]['CompanyContractorUniverse.company_address_postal_code'],
        company_phone: resultSet[0]['CompanyContractorUniverse.company_phone'],
        company_email: resultSet[0]['CompanyContractorUniverse.company_email'],
        company_class_primary_trade: resultSet[0]['CompanyContractorUniverse.company_class_primary_trade'],
        company_class_primary_trade_score: resultSet[0]['CompanyContractorUniverse.company_class_primary_trade_score'],
        company_profile_manufacturer_profiles: resultSet[0]['CompanyContractorUniverse.company_profile_manufacturer_profiles'],
        company_profile_online_profiles: resultSet[0]['CompanyContractorUniverse.company_profile_online_profiles'],
        company_profile_reviews_ratings_avg: resultSet[0]['CompanyContractorUniverse.company_profile_reviews_ratings_avg'],
        company_profile_association_profiles: resultSet[0]['CompanyContractorUniverse.company_profile_association_profiles'],
        company_profile_utilities: null,
        company_stat_contact_cnt: resultSet[0]['CompanyContractorUniverse.company_contact_cnt'],
        company_input_cnt: resultSet[0]['CompanyContractorUniverse.company_input_cnt'],
        company_source_cnt: resultSet[0]['CompanyContractorUniverse.company_source_cnt'],
        company_authoritative_source_cnt: resultSet[0]['CompanyContractorUniverse.company_authoritative_source_cnt'],
        company_source_labels: resultSet[0]['CompanyContractorUniverse.company_source_labels'],
        company_source_first_touch: resultSet[0]['CompanyContractorUniverse.company_source_first_touch'],
        company_source_last_touch: resultSet[0]['CompanyContractorUniverse.company_source_last_touch'],
        company_profile_cnt: resultSet[0]['CompanyContractorUniverse.company_profile_cnt'],
        company_license_cnt: resultSet[0]['CompanyContractorUniverse.company_license_cnt'],
        company_permit_cnt: resultSet[0]['CompanyContractorUniverse.company_permit_cnt'],
        company_policy_cnt: resultSet[0]['CompanyContractorUniverse.company_policy_cnt'],
        contact_id: resultSet[0]['CompanyContractorUniverse.contact_id'],
        contact_name_given_name: resultSet[0]['CompanyContractorUniverse.contact_name_given_name'],
        contact_name_surname: resultSet[0]['CompanyContractorUniverse.contact_name_surname'],
        contact_title: resultSet[0]['CompanyContractorUniverse.contact_title'],
        company_class_trades: resultSet[0]['CompanyContractorUniverse.company_class_trades'],
        companyIndResidential: resultSet[0]['CompanyContractorUniverse.company_ind_residential'],
        companyIndCommercial: resultSet[0]['CompanyContractorUniverse.company_ind_commercial'],
        companyIndIndustrial: resultSet[0]['CompanyContractorUniverse.company_ind_industrial'],
        companyIndExterior: resultSet[0]['CompanyContractorUniverse.company_ind_exterior'],
        companyIndInterior: resultSet[0]['CompanyContractorUniverse.company_ind_interior'],
        companyIndLicensed: resultSet[0]['CompanyContractorUniverse.company_ind_licensed'],
        companyIndBonded: resultSet[0]['CompanyContractorUniverse.company_ind_bonded'],
        companyIndInsured: resultSet[0]['CompanyContractorUniverse.company_ind_insured'],
        companyProfileCnt: resultSet[0]['CompanyContractorUniverse.company_profile_cnt'],
        companyProfileManufacturerCnt: resultSet[0]['CompanyContractorUniverse.company_profile_manufacturer_profile_cnt'],
        companyProfileAssociationCnt: resultSet[0]['CompanyContractorUniverse.company_profile_association_profile_cnt'],
        companyLicenseCnt: resultSet[0]['CompanyContractorUniverse.company_license_cnt'],
        companyPolicyCnt: resultSet[0]['CompanyContractorUniverse.company_policy_cnt'],
        companyPermitCnt: resultSet[0]['CompanyContractorUniverse.company_permit_cnt'],
        companyStatContactCnt: resultSet[0]['CompanyContractorUniverse.company_contact_cnt'],
        companySourceCnt: resultSet[0]['CompanyContractorUniverse.company_source_cnt'],
        contactPhone: resultSet[0]['CompanyContractorUniverse.contact_phone'],
        contactEmail: resultSet[0]['CompanyContractorUniverse.contact_email'],
        contactEmailStatus: resultSet[0]['CompanyContractorUniverse.contact_email_status'],
    };
}
