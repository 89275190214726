/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import {get as _get, filter} from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Image,
} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faGlobe,
    faEnvelope,
    faPhone,
    faAt,
} from '@fortawesome/free-solid-svg-icons';

import {APP_COLORS} from '../../../../common/Constants';
import ExploreMapFlyoutDetailLoadingSpinner from './ExploreMapFlyoutDetailLoadingSpinner';
import ExploreMapFlyoutSelectedBadges from './ExploreMapFlyoutSelectedBadges';

import {get} from '../../../../common/Api';
import config from '../../../../config';
import companyIcon from '../../../../common/assets/img/ToolbeltIcons_Artboard_19.png'
import {getListImportCompanyRecord,
    getListImportContactRecord,
    removeCubeTableName
} from "../../../../common/services/ListService";
import {
    faCalculator, faIdBadge, faSatelliteDish,
    faUser
} from "@fortawesome/free-solid-svg-icons";

import {
    faFileCertificate
} from '@fortawesome/pro-solid-svg-icons';

import {ExploreMapFlyoutSelectedDetailSection} from './ExploreMapFlyoutSelectedDetailSection';

export default function ExploreMapFlyoutDetailSelectedImportListCompany (props) {

    const [loading, setLoading] = useState(true);
    const [selectedRecord, setSelectedRecord] = useState(null);

    useEffect(() => {
        console.log('ExploreMapFlyoutDetailSelectedImportListCompany - ListCompany');
        setLoading(true);
        _getRecord(props.selectedPoint)
            .then((response) => {
                setLoading(false);
                setSelectedRecord(response);
            });
    }, [props.selectedPoint]);

    if (loading) return (<ExploreMapFlyoutDetailLoadingSpinner/>);

    if (selectedRecord) {
        const {
            company_name,
            company_address_delivery_line_1,
            company_address_city,
            company_address_region,
            company_address_postal_code,
            company_stat_contact_cnt,
            company_profile_cnt,
            company_license_cnt,
            company_permit_cnt,
            company_policy_cnt,
        } = selectedRecord.listRecord;
        const locationAddressLastLine = `${company_address_city}, ${company_address_region} ${company_address_postal_code}`

        let matchedToHeader;
        if (selectedRecord.matchedCompanyId) {
            matchedToHeader = (
                <div className="header-text-container">
                    <span className="header-text"><span style={{color: APP_COLORS['green']}}>MATCHED: </span>{selectedRecord.matchedCompanyId}</span>
                </div>
            )
        }

        const densityIcons = [
            {attr: 'company_url', icon: faGlobe},
            {attr: 'company_address_delivery_line_1', icon: faEnvelope},
            {attr: 'company_phone', icon: faPhone},
            {attr: 'company_email', icon: faAt}
        ];
        const densityIconsJsx = [];
        densityIcons.forEach((i, idx) => {
            const attr = _get(selectedRecord, `listRecord.${i.attr}`) || _get(selectedRecord, `productRecord.${i.attr}`);
            if (attr) {
                let marginLeft = '10px';
                if (densityIconsJsx.length === 0) marginLeft = '0px';
                // TODO: Garbage data ....
                if (i.attr === 'company_url' && attr !== 'null') {
                    densityIconsJsx.push(
                        <div key={idx} style={{marginLeft: marginLeft}}>
                            <a
                                href={`https://${attr && attr.replace(/(^\w+:|^)\/\//, '')}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    color={APP_COLORS['orange']}
                                    icon={i.icon}
                                    style={{fontSize: '16px'}}
                                />
                            </a>
                        </div>
                    )
                } else {
                    densityIconsJsx.push(
                        <div key={idx} style={{marginLeft: marginLeft}}>
                            <FontAwesomeIcon
                                color={APP_COLORS['blue']}
                                icon={i.icon}
                                style={{fontSize: '16px'}}
                            />
                        </div>
                    )
                }
            }
        });

        const badgesIcons = [
            {attr: 'company_stat_contact_cnt', value: company_stat_contact_cnt, icon: faUser, suffix: 'Contacts'},
            {attr: 'company_profile_cnt', value: company_profile_cnt, icon: faSatelliteDish, suffix: 'Profiles'},
            {attr: 'company_license_cnt', value: company_license_cnt, icon: faIdBadge, suffix: 'Licenses'},
            {attr: 'company_permit_cnt', value: company_permit_cnt, icon: faCalculator, suffix: 'Permits'},
            {attr: 'company_policy_cnt', value: company_policy_cnt, icon: faFileCertificate, suffix: 'Policies'}
        ];

        const badgesIconsJsx = [];
        badgesIcons.forEach((i) => {
            if (parseInt(i.value, 10) > 0) {
                badgesIconsJsx.push(
                    <div key={badgesIconsJsx.length + 1} className="selected-point-detail-record-badges-icons-item"
                         data-for='budgesIcon' data-tip={`${i.value} ${i.suffix}`}>
                        <span>
                            <FontAwesomeIcon
                                color={APP_COLORS['blue']}
                                icon={i.icon}
                                style={{fontSize: '16px'}}
                            /><span style={{fontSize: '14px', fontWeight: '600'}}> {i.value}</span>
                        </span>
                    </div>
                )
            }
        });

        return (
            <Row>
                <Col>
                    <div className="explore-map-flyout-detail explore-map-flyout-detail-data-point">
                        {matchedToHeader && (
                            matchedToHeader
                        )}
                        <ExploreMapFlyoutSelectedDetailSection
                            name={company_name}
                            address1={company_address_delivery_line_1}
                            address2={locationAddressLastLine}
                            densityIconsJsx={densityIconsJsx}
                            detailIconJsx={
                                <Image
                                    className="icon"
                                    src={companyIcon}
                                    alt="ToolBeltData Company Icon"
                                    fluid
                                />
                            }
                        />
                        { filter(badgesIcons, icon => parseInt(icon.value, 10) > 0).length > 0 &&
                            <ExploreMapFlyoutSelectedBadges
                                badgesIcons={badgesIcons}
                            />
                        }
                    </div>
                </Col>
            </Row>
        )
    }
    return null;
}

ExploreMapFlyoutDetailSelectedImportListCompany.propTypes = {
    selectedPoint: PropTypes.object,
};

async function _getRecord(selectedPoint) {
    const {
        id,
        list_id,
        company_id,
        listType
    } = selectedPoint.properties;

    if (selectedPoint.type === 'OVERLAY' && company_id) {
        // TODO cube data.
        const listRecord = await get(true, `${config.services.ATLAS.baseUrl}/lists/${list_id}/records/${id}`, {});
        const productRecord = await get(true, `${config.services.ATLAS.dataBaseUrl}/pro-companies/${company_id}`, {});
        return {matchedCompanyId: company_id, listRecord, productRecord};
    }

    if (selectedPoint.type === 'OVERLAY' && !company_id) {
        if (listType === 'IMPORT_COMPANY') {
            const results = await getListImportCompanyRecord(list_id, id);
            if (results && results.length > 0) {
                const listRecord = {
                    ...removeCubeTableName(results[0]),
                };
                return {listRecord, productRecord: null};
            }

            return {listRecord: null, productRecord: null};
        }

        if (listType === 'IMPORT_CONTACT') {
            const results = await getListImportContactRecord(list_id, id);
            if (results && results.length > 0) {
                const listRecord = {
                    ...removeCubeTableName(results[0]),

                };
                return {listRecord, productRecord: null};
            }

            return {listRecord: null, productRecord: null};
        }

    }

    if (selectedPoint.type === 'PRODUCT') {
        console.log('Handling Product Point');
    }
}
